// Layout.tsx
import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { Box} from '@mui/material';

interface LayoutProps {
  children: React.ReactNode;
}


const LayoutLandingPage: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navigation />
      <Box component="main" sx={{ flex: 1 }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
export default LayoutLandingPage