// App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import theme from './theme';
import HomePage from './pages/HomePage';
import JobList from './pages/JobList';
import Layout from './components/Layout';
import UploadPage from './pages/UploadPage';
import JobCreateForm from './components/JobCreateForm';
import JobDetailsPage from './pages/JobDetailsPage';
import LandingHomePage from './pages/LandingHomePage';
import CompanyWaitlistPage from './pages/CompanyWaitlistPage';
import ProviderRegistrationForm from './pages/ProviderRegistrationForm';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import TeamPage from './pages/TeamPage';


const App = () => {
  return (
    
        <Routes>
          <Route path="/" element={<LandingHomePage />} />
          <Route path="/early-access" element={<CompanyWaitlistPage />} />
          <Route path="/join-provider" element={<ProviderRegistrationForm />} />
          <Route path="/about" element={<AboutUsPage/>} />
          <Route path="/contact" element={<ContactUsPage/>} />
          <Route path="/team" element={<TeamPage/>} />
        </Routes>
     
   
  );
};

export default App;
