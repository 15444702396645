import React, { useState } from 'react';
import {
  
  Box,
  Button,
  Card,
  CardContent,
  Container,
 
  Stack,
  ThemeProvider,
 
  Typography,
 
  createTheme,
  
} from '@mui/material';
import {
  Menu as MenuIcon,
  KeyboardArrowRight,
  CheckCircleOutline,
} from '@mui/icons-material';
import Navigation from '../components/landingPage/Navigation';




// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
    },
    secondary: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Arial", sans-serif',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1.1rem',
      lineHeight: 1.7,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          padding: '10px 24px',
          textTransform: 'none',
          fontSize: '1rem',
        },
        containedPrimary: {
          boxShadow: '0 4px 6px rgba(25, 118, 210, 0.25)',
        },
        containedSecondary: {
          boxShadow: '0 4px 6px rgba(46, 125, 50, 0.25)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

// Navigation Component


// Feature Section
interface FeatureSectionProps {
  title: string;
  benefits: string[];
  description: string;
  imageUrl: string;
  reverse?: boolean;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  benefits,
  description,
  imageUrl,
  reverse = false,
}) => (
  <Box sx={{ py: 8 }}>
    <Container maxWidth="lg">
      <Card elevation={0}>
        <CardContent sx={{ p: 4 }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { md: '1fr 1fr' },
              gap: 6,
              alignItems: 'center',
            }}
          >
            <Box sx={{ order: { md: reverse ? 2 : 1 } }}>
              <img
                src={imageUrl}
                alt={title}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 16,
                }}
              />
            </Box>
            <Box sx={{ order: { md: reverse ? 1 : 2 } }}>
              <Typography variant="h3" gutterBottom>
                {title}
              </Typography>
              <Typography variant="h6" color="primary" gutterBottom>
                Key Benefits:
              </Typography>
              <Stack spacing={2} sx={{ mb: 4 }}>
                {benefits.map((benefit, index) => (
                  <Stack key={index} direction="row" spacing={2} alignItems="center">
                    <CheckCircleOutline color="primary" />
                    <Typography>{benefit}</Typography>
                  </Stack>
                ))}
              </Stack>
              <Typography color="text.secondary" paragraph>
                {description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<KeyboardArrowRight />}
                href="/about"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  </Box>
);

// Motto Section
const Motto = () => (
  <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
    <Container maxWidth="md">
      <Stack spacing={3} textAlign="center">
        <Typography variant="overline" color="primary">
          What we believe in
        </Typography>
        <Typography variant="body1">
          We believe quality data should be accessible to every ML team. By connecting companies with verified data providers,
          we're making ML development faster, more affordable, and more inclusive. Our platform cuts data collection costs
          by 40% while creating earning opportunities for thousands of providers worldwide. Join us in building the future
          of machine learning, one dataset at a time.
        </Typography>
      </Stack>
    </Container>
  </Box>
);

const Hero = () => (
    <Box
      sx={{
        position: 'relative',
        pt: { xs: 12, md: 16 },
        pb: { xs: 8, md: 12 },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url('/crowdml-header.svg')`, // Replace with your image name
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0)', // Darker overlay
          zIndex: 1,
        },
      }}
    >
      <Container 
        maxWidth="lg" 
        sx={{ 
          position: 'relative',
          zIndex: 2 // Place content above the overlay
        }}
      >
        <Stack 
          spacing={4} 
          alignItems="center" 
          textAlign="center"
          sx={{
            maxWidth: 'lg',
            mx: 'auto',
          }}
        >
          <Typography 
            variant="h1" 
            component="h1"
            sx={{
              color: 'black',
              fontWeight: 700,
              fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.3)',
              mb: 2,
            }}
          >
            Quality ML Training Data, On Demand
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              maxWidth: 'md', 
              mx: 'auto', 
              color: 'black',
              textShadow: '0 1px 2px rgba(0,0,0,0.3)',
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              mb: 4,
            }}
          >
            Connect with verified data providers to build better ML models
          </Typography>
  
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            endIcon={<KeyboardArrowRight />}
            href='/about'
            sx={{
              color: 'black',
              borderColor: 'white',
              borderWidth: 2,
              px: 4,
              py: 1.5,
              fontSize: '1.125rem',
              '&:hover': {
                borderWidth: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              textTransform: 'none',
              borderRadius: '50px',
            }}
          >
            Learn More
          </Button>
        </Stack>
      </Container>
    </Box>
  );
  
  

// CTA Section
const CTASection = () => (
  <Box
    sx={{
      bgcolor: 'primary.dark',
      color: 'white',
      py: 8,
      background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
    }}
  >
    <Container maxWidth="md">
      <Stack spacing={4} alignItems="center" textAlign="center">
        <Typography variant="h2">
          Quality ML Training Data, On Demand
        </Typography>
        <Typography variant="h5" sx={{ opacity: 0.9 }}>
          Connect with verified data providers to build better ML models
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{ mt: 4 }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            href='/early-access'
          >
            Get Early Access
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            href='/join-provider'
          >
            Join as Provider
          </Button>
        </Stack>
      </Stack>
    </Container>
  </Box>
);

// Main App
const App = () => {
  const features = [
    {
      title: "Streamline Your ML Data Collection",
      benefits: [
        "Cut data collection costs by 40%",
        "Access 10,000+ verified providers",
        "Launch projects in minutes"
      ],
      description: "Stop struggling with unreliable data collection. Our platform helps ML teams get quality training data faster and cheaper. With real-time progress tracking, quality controls, and a verified provider network, you can focus on building great models instead of managing data collection.",
      imageUrl: "/CompanyDashboard.png"
    },
    {
      title: "Earn Money Contributing to AI Development",
      benefits: [
        "Flexible earning opportunities",
        "Weekly payouts",
        "Work from anywhere"
      ],
      description: "Turn your free time into income by helping train the next generation of AI models. Choose from diverse tasks, work at your own pace, and get paid weekly. Our clear guidelines and instant feedback help you succeed from day one.",
      imageUrl: "/ProviderDashboard.png",
      reverse: true
    },
    {
      title: "Quality-Focused Data Collection Made Simple",
      benefits: [
        "Clear task guidelines",
        "Instant feedback",
        "Fair compensation"
      ],
      description: "Whether you're labelling images, transcribing audio, or annotating text, our intuitive interface makes it easy to deliver quality work. Get clear instructions, real-time validation, and instant feedback to maximize your earnings.",
      imageUrl: "/DataSubmission.png"
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh' }}>
        <Navigation />
        <Hero />
        <Motto />
        <Box component="main">
          {features.map((feature, index) => (
            <FeatureSection key={index} {...feature} />
          ))}
        </Box>
        <CTASection />
      </Box>
    </ThemeProvider>
  );
};

export default App;