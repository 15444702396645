import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  useTheme,
} from '@mui/material';
import LayoutLandingPage from '../components/landingPage/LayoutLandingPage';
import SecurityIcon from '@mui/icons-material/Security';
import PaidIcon from '@mui/icons-material/Paid';
import UpdateIcon from '@mui/icons-material/Update';

const AboutUsPage = () => {
  const theme = useTheme();

  const values = [
    {
      icon: <PaidIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />,
      title: 'Fair Compensation',
      description: 'We\'re committed to fairly compensating our data providers while keeping costs reasonable for companies. Our transparent pricing model ensures everyone benefits.',
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />,
      title: 'Security & Privacy',
      description: 'Built with security at its core, our platform ensures safe data handling and protects both providers\' and companies\' interests.',
    },
    {
      icon: <UpdateIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />,
      title: 'Continuous Innovation',
      description: 'We\'re constantly improving our platform based on user feedback and industry developments, staying ahead of ML data collection needs.',
    },
   
  ];

  return (
    <LayoutLandingPage>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'grey.900',
          color: 'white',
          pt: { xs: 15, md: 20 },
          pb: { xs: 8, md: 12 },
          backgroundImage: `url('/crowdml-header.svg')`, // Replace with your image name
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            component="h1"
            sx={{
                color: 'black',
              fontSize: { xs: '3rem', md: '4rem' },
              fontWeight: 700,
              textAlign: 'center',
              mb: 4,
            }}
          >
            About Us
          </Typography>
        </Container>
      </Box>

      {/* Mission Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Stack spacing={6}>
            {/* Mission */}
            <Box>
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  fontWeight: 700,
                  textAlign: 'center',
                  mb: 4,
                }}
              >
                Our Mission
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '1.125rem',
                  textAlign: 'center',
                  color: 'text.secondary',
                  maxWidth: '800px',
                  mx: 'auto',
                }}
              >
                We're revolutionizing how machine learning teams collect training data. Our mission is to democratize ML development by connecting companies with quality data providers while creating earning opportunities for people worldwide.
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                height: '1px',
                bgcolor: 'grey.200',
                my: 4,
              }}
            />

            {/* Why We Started */}
            <Box>
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  fontWeight: 700,
                  textAlign: 'center',
                  mb: 4,
                }}
              >
                Why We Started
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '1.125rem',
                  textAlign: 'center',
                  color: 'text.secondary',
                  maxWidth: '800px',
                  mx: 'auto',
                }}
              >
                In our combined experience at Sky, Aviva, and other tech companies, we consistently faced the same challenge: getting high-quality, diverse training data for ML models was expensive, time-consuming, and often unreliable. We built CrowdML to solve this problem, making quality data collection accessible and affordable while ensuring fair compensation for data providers.
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Values Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="lg">
          <Stack spacing={4} sx={{ mb: 6 }}>
            <Typography
              variant="overline"
              sx={{
                color: 'primary.main',
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              What we are best at
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Our Values
            </Typography>
          </Stack>

          <Grid container spacing={4}>
            {values.map((value, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                    boxShadow: 'none',
                    bgcolor: 'transparent',
                  }}
                >
                  <CardContent>
                    <Stack spacing={2} alignItems="center" textAlign="center">
                      {value.icon}
                      <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {value.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                      >
                        {value.description}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </LayoutLandingPage>
  );
};

export default AboutUsPage;