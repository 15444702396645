import  { useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  ListItemButton,
  useMediaQuery,
  Container,
  Button,
  Stack,
  useTheme,
} from '@mui/material';
import {
  Menu as MenuIcon,
 
} from '@mui/icons-material';

import { Link, useNavigate } from 'react-router-dom';

interface NavItem {
  text: string;
  link: string;
}
const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();


  const navItems: NavItem[] = [
    { text: 'About', link: '/about' },
    { text: 'Team', link: '/team' },
    { text: 'Contact', link: '/contact' },
  ];

  const handleLogoClick = () => {
    navigate('/');
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  return (
    <AppBar position="fixed" color="default" elevation={1}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
            onClick={handleLogoClick}
            component={Link}
            to="/">
            <img
              src="/crowdml-logo-fixed.svg"
              alt="CrowdML Logo"
              style={{ height: 40 }}
            />
          </Box>

          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Stack direction="row" spacing={4} alignItems="center">
              {navItems.map((item) => (
                <Button
                  key={item.text}
                  color="inherit"
                  href={item.link}
                >
                  {item.text}
                </Button>
              ))}
              <Button
                variant="contained"
                color="primary"
                href="/early-access"
              >
                Get Early Access
              </Button>
              <Button
                variant="contained"
                color="secondary"
                href="/join-provider"
              >
                Join as Provider
              </Button>
            </Stack>
          )}
        </Toolbar>
      </Container>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
      >
        <Box sx={{ width: 250 }}>
          <List>
            {navItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component="a" href={item.link}>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ my: 2 }} />
            <ListItem sx={{ px: 2, py: 1 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                href="/early-access"
              >
                Get Early Access
              </Button>
            </ListItem>
            <ListItem sx={{ px: 2, py: 1 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                href="/join-provider"
              >
                Join as Provider
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};
export default Navigation