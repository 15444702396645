import axios, { AxiosError } from 'axios';
import { Job, FileUpload, FileStatus } from '../models/job';

export interface CompanyWaitlist {
  companyName: string;
  contactPerson: string;
  businessEmail: string;
  industry: string;
  dataType: string;
  monthlyVolume: string;
  timeline: string;
  additionalRequirements?: string;
  budget: string;
  jobsPerYear: string;
}

export interface ApiResponse {
  message: string;
  id?: string;
}


export interface ApiError {
  message: string;
  code?: string;
}
const API_URL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

console.log('API Base URL:', API_URL); 

export const submitEarlyAccess = async (data: CompanyWaitlist): Promise<ApiResponse> => {
  try {
    const response = await api.post<ApiResponse>('/early-access', data);
    return response.data;
  } catch (error) {
    // Handle different types of errors
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiError>;
      
      if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(axiosError.response.data?.message || 'Server error occurred');
      } else if (axiosError.request) {
        // The request was made but no response was received
        throw new Error('No response received from server');
      } else {
        // Something happened in setting up the request
        throw new Error('Error setting up the request');
      }
    } else {
      // Handle non-Axios errors
      throw new Error('An unexpected error occurred');
    }
  }
};

export const fetchJobs = async (): Promise<Job[]> => {
  const response = await api.get('/Job');
  return response.data;
};

export const createJob = async (job: Partial<Job>): Promise<Job> => {
  const response = await api.post('/Job', job);
  return response.data;
};

export const fetchJobById = async (id: string): Promise<Job> => {
  const response = await api.get(`/Job/${id}`);
  return response.data;
};

export const fetchFilesByJobId = async (jobId: string): Promise<FileUpload[]> => {
  const response = await api.get(`/Job/${jobId}/files`);
  console.log(response.data)
  return response.data;
};

export const uploadFile = async (jobId: string, file: File): Promise<FileUpload> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await api.post(`/Upload/${jobId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const downloadFile = async (jobId: string, fileName: string): Promise<Blob> => {
  const response = await api.get(`/Job/download/${jobId}/${fileName}`, {
    responseType: 'blob',
  });
  return response.data;
};

export const updateFileUploadStatus = async (fileUploadId: string, status: FileStatus): Promise<FileUpload> => {
  const response = await api.post(`/Upload/${fileUploadId}/status/${status}`);
  return response.data;
};

export default api;
