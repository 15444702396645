import React, { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  SelectChangeEvent,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  OutlinedInput,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import LayoutLandingPage from '../components/landingPage/LayoutLandingPage';

interface FormData {
  fullName: string;
  email: string;
  location: string;
  skills: string[];
  languages: string[];
  devices: string[];
  availability: string;
  experience: string;
}

interface FormErrors {
  [key: string]: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProviderRegistrationForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    location: '',
    skills: [],
    languages: [],
    devices: [],
    availability: '',
    experience: '',
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  // Options for select fields
  const skillOptions = [
    'Image Annotation',
    'Text Labeling',
    'Audio Transcription',
    'Video Annotation',
    'Data Cleaning',
    'Content Moderation',
    'Sentiment Analysis',
    'Object Detection',
    'Data Entry',
    'Translation',
  ];

  const languageOptions = [
    'English',
    'Spanish',
    'French',
    'German',
    'Chinese',
    'Japanese',
    'Korean',
    'Arabic',
    'Russian',
    'Portuguese',
  ];

  const deviceOptions = [
    'Desktop Computer',
    'Laptop',
    'Smartphone',
    'Tablet',
    'High-Speed Internet',
    'Microphone',
    'Webcam',
    'Dual Monitors',
  ];

  const availabilityOptions = [
    '0-10 hours/week',
    '10-20 hours/week',
    '20-30 hours/week',
    '30-40 hours/week',
    '40+ hours/week',
  ];

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!formData.fullName) newErrors.fullName = 'Full name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.location) newErrors.location = 'Location is required';
    if (formData.skills.length === 0) newErrors.skills = 'At least one skill is required';
    if (formData.languages.length === 0) newErrors.languages = 'At least one language is required';
    if (formData.devices.length === 0) newErrors.devices = 'At least one device is required';
    if (!formData.availability) newErrors.availability = 'Availability is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: typeof value === 'string' ? value.split(',') : value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
      setShowSuccessAlert(true);
      // Reset form after successful submission
      setFormData({
        fullName: '',
        email: '',
        location: '',
        skills: [],
        languages: [],
        devices: [],
        availability: '',
        experience: '',
      });
    }
  };

  return (
    <LayoutLandingPage>
      <Box
        sx={{
          bgcolor: 'grey.50',
          pt: { xs: 12, sm: 16 },
          pb: { xs: 6, sm: 8 },
        }}
      >
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 4 },
              borderRadius: 2,
            }}
          >
            <Stack spacing={4}>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  color="primary"
                  fontWeight="bold"
                >
                  Join as a Provider
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Start earning by contributing to AI development
                </Typography>
              </Box>

              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleTextChange}
                      error={!!errors.fullName}
                      helperText={errors.fullName}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleTextChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Location"
                      name="location"
                      value={formData.location}
                      onChange={handleTextChange}
                      error={!!errors.location}
                      helperText={errors.location}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.availability}>
                      <InputLabel>Availability</InputLabel>
                      <Select
                        name="availability"
                        value={formData.availability}
                        onChange={handleSelectChange}
                        label="Availability"
                      >
                        {availabilityOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth required error={!!errors.skills}>
                      <InputLabel>Skills</InputLabel>
                      <Select
                        multiple
                        name="skills"
                        value={formData.skills}
                        onChange={handleMultiSelectChange}
                        input={<OutlinedInput label="Skills" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {skillOptions.map((skill) => (
                          <MenuItem key={skill} value={skill}>
                            {skill}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth required error={!!errors.languages}>
                      <InputLabel>Fluent Languages</InputLabel>
                      <Select
                        multiple
                        name="languages"
                        value={formData.languages}
                        onChange={handleMultiSelectChange}
                        input={<OutlinedInput label="Fluent Languages" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {languageOptions.map((language) => (
                          <MenuItem key={language} value={language}>
                            {language}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth required error={!!errors.devices}>
                      <InputLabel>Available Devices</InputLabel>
                      <Select
                        multiple
                        name="devices"
                        value={formData.devices}
                        onChange={handleMultiSelectChange}
                        input={<OutlinedInput label="Available Devices" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {deviceOptions.map((device) => (
                          <MenuItem key={device} value={device}>
                            {device}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Previous Experience"
                      name="experience"
                      multiline
                      rows={4}
                      value={formData.experience}
                      onChange={handleTextChange}
                      placeholder="Please share any relevant experience in data annotation, content moderation, or related fields..."
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth={isMobile}
                      endIcon={<SendIcon />}
                      sx={{
                        py: 1.5,
                        px: 4,
                        mt: 2,
                      }}
                    >
                      Submit Application
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Stack>
          </Paper>
        </Container>

        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={6000}
          onClose={() => setShowSuccessAlert(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setShowSuccessAlert(false)} 
            severity="success" 
            variant="filled"
            sx={{ width: '100%' }}
          >
            Your application has been submitted successfully!
          </Alert>
        </Snackbar>
      </Box>
    </LayoutLandingPage>
  );
};

export default ProviderRegistrationForm;