import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  Avatar,
  IconButton,
  useTheme,
  Chip,
} from '@mui/material';
import LayoutLandingPage from '../components/landingPage/LayoutLandingPage';
import {
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  GitHub as GitHubIcon,
} from '@mui/icons-material';

interface TeamMember {
  id: number;
  name: string;
  role: string;
  bio: string;
  skills: string[];
  avatar: string;
  social: {
    linkedin?: string;
    twitter?: string;
    github?: string;
  };
}

const TeamPage = () => {
  const theme = useTheme();

  const teamMembers: TeamMember[] = [
    {
      id: 1,
      name: 'Omar Maynard',
      role: 'Founder',
      bio: "AWS-certified engineer previously from Sky, specializing in ML infrastructure and data platforms. Architected cloud solutions processing millions of data points, now building CrowdML's scalable backend infrastructure.",
      skills: ['AWS Cloud Architecture','Machine Learning Infrastructure', 'Product Vision'],
      avatar: '/api/placeholder/300/300',
      social: {
        linkedin: 'https://www.linkedin.com/in/omar-maynard-370806104/',
        // twitter: 'https://twitter.com',
      },
    },
    {
      id: 2,
      name: 'Abdullah Ayyaz',
      role: 'Co-Founder',
      bio: "Quality assurance expert with 4+ years at Aegon and Team ITG. Implements comprehensive testing strategies and automation frameworks to ensure CrowdML's data quality and platform reliability.",
      skills: ['Quality Assurance Strategy','Performance Testing','Test Framework Development'],
      avatar: '/api/placeholder/300/300',
      social: {
        linkedin: 'https://www.linkedin.com/in/abdullah-ayyaz/',
        // github: 'https://github.com',
      },
    },
    {
      id: 3,
      name: 'Alex Bishop',
      role: 'Co-Founder',
      bio: "AWS-certified DevSecOps engineer at Cadent Gas. Combines security expertise with infrastructure automation to build and maintain CrowdML's secure, scalable cloud environment.",
      skills: ['Cloud Security Architecture', 'Infrastructure Automation', 'Security Compliance & Monitoring'],
      avatar: '/api/placeholder/300/300',
      social: {
        linkedin: 'https://www.linkedin.com/in/aljbishop/',
        // twitter: 'https://twitter.com',
        // github: 'https://github.com',
      },
    },
    {
      id: 4,
      name: 'Mohammad Bilaal',
      role: 'Co-Founder',
      bio: "Former NHS Digital developer with extensive experience in scalable data platforms. Specializes in React and .NET microservices architecture, bringing healthcare-grade reliability to CrowdML's core platform infrastructure.",
      skills: ['Microservices Architecture','Real-time Application Development', 'User Experience'],
      avatar: '/api/placeholder/300/300',
      social: {
        linkedin: 'https://linkedin.com',
        // twitter: 'https://twitter.com',
      },
    },
  ];

  return (
    <LayoutLandingPage >
      {/* Hero Section */}
      <Box
        sx={{
          color: 'white',
          pt: { xs: 15, md: 20 },
          pb: { xs: 8, md: 12 },
          backgroundImage: `url('/crowdml-header.svg')`, // Replace with your image name
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={4} alignItems="center" textAlign="center">
            <Typography
              variant="h1"
              component="h1"
              sx={{
                color: 'black',
                fontSize: { xs: '3rem', md: '4rem' },
                fontWeight: 700,
                mb: 2,
              }}
            >
              Our Team
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: 'black',
                maxWidth: '800px',
                opacity: 0.9,
                lineHeight: 1.6,
              }}
            >
              Meet the experts behind CrowdML. With decades of combined experience in ML, 
              data science, and product development, we're passionate about revolutionizing 
              ML data collection.
            </Typography>
          </Stack>
        </Container>
      </Box>

      {/* Team Members Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {teamMembers.map((member) => (
              <Grid item xs={12} md={6} key={member.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: (theme) => theme.shadows[8],
                    },
                  }}
                >
                  <CardContent sx={{ p: 4, flex: 1 }}>
                    <Stack spacing={3}>
                      {/* Header */}
                      <Stack 
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={3}
                        alignItems={{ xs: 'center', sm: 'flex-start' }}
                      >
                        <Avatar
                          src={member.avatar}
                          sx={{
                            width: 120,
                            height: 120,
                            borderRadius: 3,
                            boxShadow: (theme) => theme.shadows[2],
                          }}
                        />
                        <Box 
                          sx={{ 
                            textAlign: { xs: 'center', sm: 'left' },
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: '1.75rem',
                              fontWeight: 600,
                              mb: 1,
                            }}
                          >
                            {member.name}
                          </Typography>
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                              fontSize: '1.1rem',
                              fontWeight: 500,
                              mb: 2,
                            }}
                          >
                            {member.role}
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent={{ xs: 'center', sm: 'flex-start' }}
                          >
                            {member.social.linkedin && (
                              <IconButton
                                href={member.social.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                                sx={{ color: 'primary.main' }}
                              >
                                <LinkedInIcon />
                              </IconButton>
                            )}
                            {member.social.twitter && (
                              <IconButton
                                href={member.social.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                                sx={{ color: 'primary.main' }}
                              >
                                <TwitterIcon />
                              </IconButton>
                            )}
                            {member.social.github && (
                              <IconButton
                                href={member.social.github}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                                sx={{ color: 'primary.main' }}
                              >
                                <GitHubIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Box>
                      </Stack>

                      {/* Bio */}
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ lineHeight: 1.7 }}
                      >
                        {member.bio}
                      </Typography>

                      {/* Skills */}
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1.5, color: 'text.secondary' }}
                        >
                          Expertise
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap="wrap"
                          gap={1}
                        >
                          {member.skills.map((skill) => (
                            <Chip
                              key={skill}
                              label={skill}
                              size="small"
                              sx={{
                                bgcolor: 'primary.50',
                                color: 'primary.700',
                                '&:hover': {
                                  bgcolor: 'primary.100',
                                },
                              }}
                            />
                          ))}
                        </Stack>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </LayoutLandingPage >
  );
};

export default TeamPage;