import React, { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import LayoutLandingPage from '../components/landingPage/LayoutLandingPage';
import { submitEarlyAccess, CompanyWaitlist } from '../services/api';
import { AxiosError } from 'axios';


interface FormData {
  companyName: string;
  contactPerson: string;
  businessEmail: string;
  industry: string;
  dataType: string;
  monthlyVolume: string;
  timeline: string;
  additionalRequirements: string;
  budget: string;
  jobsPerYear: string;
}

interface FormErrors {
  [key: string]: string;
}

const CompanyWaitlistPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    contactPerson: '',
    businessEmail: '',
    industry: '',
    dataType: '',
    monthlyVolume: '',
    timeline: '',
    additionalRequirements: '',
    budget: '',
    jobsPerYear: '',
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [submitStatus, setSubmitStatus] = useState<{
    show: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    show: false,
    message: '',
    severity: 'success',
  });

  const industries = [
    'Technology',
    'Finance',
    'Healthcare',
    'Retail',
    'Manufacturing',
    'Education',
    'Other',
  ];

  const dataTypes = [
    'Image Data',
    'Text Data',
    'Audio Data',
    'Video Data',
    'Sensor Data',
    'Other',
  ];

  const timelines = [
    'Immediate',
    'Within 1 Month',
    'Within 3 Months',
    'Within 6 Months',
    'More than 6 Months',
  ];

  const budgetRanges = [
    '$0 - $5,000',
    '$5,000 - $10,000',
    '$10,000 - $50,000',
    '$50,000+',
  ];

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!formData.companyName) newErrors.companyName = 'Company name is required';
    if (!formData.contactPerson) newErrors.contactPerson = 'Contact person is required';
    if (!formData.businessEmail) {
      newErrors.businessEmail = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.businessEmail)) {
      newErrors.businessEmail = 'Invalid email address';
    }
    if (!formData.industry) newErrors.industry = 'Industry is required';
    if (!formData.dataType) newErrors.dataType = 'Data type is required';
    if (!formData.monthlyVolume) newErrors.monthlyVolume = 'Monthly volume is required';
    if (!formData.timeline) newErrors.timeline = 'Timeline is required';
    if (!formData.budget) newErrors.budget = 'Budget range is required';
    if (!formData.jobsPerYear) newErrors.jobsPerYear = 'Expected jobs per year is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handler for text inputs
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  // Handler for select inputs
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user selects an option
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const response = await submitEarlyAccess(formData as CompanyWaitlist);
      
      setSubmitStatus({
        show: true,
        message: response.message || 'Thank you for your interest! We\'ll be in touch soon.',
        severity: 'success',
      });

      // Reset form
      setFormData({
        companyName: '',
        contactPerson: '',
        businessEmail: '',
        industry: '',
        dataType: '',
        monthlyVolume: '',
        timeline: '',
        additionalRequirements: '',
        budget: '',
        jobsPerYear: '',
      });
      
    } catch (error) {
      console.error('Submission error:', error);
      
      let errorMessage = 'There was an error submitting your request. Please try again.';
      
      // Handle specific error messages
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      setSubmitStatus({
        show: true,
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LayoutLandingPage>
        <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'grey.50',
          pt: { xs: 12, sm: 16 },
          pb: { xs: 6, sm: 8 },
        }}
      >
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 4 },
              borderRadius: 2,
            }}
          >
            <Stack spacing={4}>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  color="primary"
                  fontWeight="bold"
                >
                  Get Early Access
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Join the future of ML data collection
                </Typography>
              </Box>

              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleTextChange}
                      error={!!errors.companyName}
                      helperText={errors.companyName}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Contact Person"
                      name="contactPerson"
                      value={formData.contactPerson}
                      onChange={handleTextChange}
                      error={!!errors.contactPerson}
                      helperText={errors.contactPerson}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Business Email"
                      name="businessEmail"
                      type="email"
                      value={formData.businessEmail}
                      onChange={handleTextChange}
                      error={!!errors.businessEmail}
                      helperText={errors.businessEmail}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.industry}>
                      <InputLabel>Industry</InputLabel>
                      <Select
                        name="industry"
                        value={formData.industry}
                        onChange={handleSelectChange}
                        label="Industry"
                      >
                        {industries.map((industry) => (
                          <MenuItem key={industry} value={industry}>
                            {industry}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.dataType}>
                      <InputLabel>Data Type Needed</InputLabel>
                      <Select
                        name="dataType"
                        value={formData.dataType}
                        onChange={handleSelectChange}
                        label="Data Type Needed"
                      >
                        {dataTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Expected Monthly Volume"
                      name="monthlyVolume"
                      placeholder="e.g., 1000 images/month"
                      value={formData.monthlyVolume}
                      onChange={handleTextChange}
                      error={!!errors.monthlyVolume}
                      helperText={errors.monthlyVolume}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.timeline}>
                      <InputLabel>Timeline</InputLabel>
                      <Select
                        name="timeline"
                        value={formData.timeline}
                        onChange={handleSelectChange}
                        label="Timeline"
                      >
                        {timelines.map((timeline) => (
                          <MenuItem key={timeline} value={timeline}>
                            {timeline}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.budget}>
                      <InputLabel>Average Budget</InputLabel>
                      <Select
                        name="budget"
                        value={formData.budget}
                        onChange={handleSelectChange}
                        label="Average Budget"
                      >
                        {budgetRanges.map((range) => (
                          <MenuItem key={range} value={range}>
                            {range}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Expected Jobs Per Year"
                      name="jobsPerYear"
                      type="number"
                      value={formData.jobsPerYear}
                      onChange={handleTextChange}
                      error={!!errors.jobsPerYear}
                      helperText={errors.jobsPerYear}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Additional Requirements"
                      name="additionalRequirements"
                      multiline
                      rows={4}
                      value={formData.additionalRequirements}
                      onChange={handleTextChange}
                      placeholder="Please share any specific requirements or questions you have..."
                    />
                  </Grid>

                  <Grid item xs={12}>
                  <Button
      type="submit"
      variant="contained"
      size="large"
      disabled={isSubmitting}
      endIcon={isSubmitting ? <CircularProgress size={20} /> : <SendIcon />}
      sx={{ 
        px: 4,
        py: 1.5,
        borderRadius: 50,
        minWidth: { xs: '100%', sm: 200 },
      }}
    >
      {isSubmitting ? 'Submitting...' : 'Submit'}
    </Button>
                  </Grid>
                </Grid>
              </form>
            </Stack>
          </Paper>
        </Container>

        <Snackbar
  open={submitStatus.show}
  autoHideDuration={6000}
  onClose={() => setSubmitStatus(prev => ({ ...prev, show: false }))}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
>
  <Alert 
    onClose={() => setSubmitStatus(prev => ({ ...prev, show: false }))} 
    severity={submitStatus.severity}
    variant="filled"
    sx={{ width: '100%' }}
  >
    {submitStatus.message}
  </Alert>
</Snackbar>
      </Box>
    </LayoutLandingPage>
      
   
  );
};

export default CompanyWaitlistPage;