
import React from 'react';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
const Footer = () =>{
  const currentYear = new Date().getFullYear();
  
  return (
    <Box 
      component="footer" 
      sx={{ 
        py: 3,
        px: 2,
        mt: 'auto',
        bgcolor: 'grey.100',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
            }}
          >
            © {currentYear} CrowdML
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer