import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Stack,
  Snackbar,
  Alert,
  Avatar,
  Divider,
  Link,
  useTheme,
  CircularProgress
} from '@mui/material';
import LayoutLandingPage from '../components/landingPage/LayoutLandingPage';
import { Send as SendIcon, Email as EmailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import axios from 'axios'

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

interface TeamMember {
  name: string;
  role: string;
  email: string;
//   phone: string;
  avatar: string;
}

const ContactUsPage = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    show: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const teamMembers: TeamMember[] = [
    {
      name: 'Omar Maynard',
      role: 'Founder',
      email: 'crowdml50@gmail.com',
    //   phone: '01 (650) 658 6823',
      avatar: '/api/placeholder/100/100',
    },
    {
      name: 'Abdullah Ayyaz',
      role: 'Co-Founder',
      email: 'crowdml50@gmail.com',
    //   phone: '01 (650) 658 6824',
      avatar: '/api/placeholder/100/100',
    },
    {
      name: 'Alex Bishop',
      role: 'Co-Founder',
      email: 'crowdml50@gmail.com',
    //   phone: '01 (650) 658 6825',
      avatar: '/api/placeholder/100/100',
    },
    {
        name: 'Mohammad Bilaal',
        role: 'Co-Founder',
        email: 'crowdml50@gmail.com',
        // phone: '01 (650) 658 6825',
        avatar: '/api/placeholder/100/100',
    },
  ];

  const handleSubmit = async (e: React.FormEvent): Promise<void> =>{
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
      
      setSubmitStatus({
        show: true,
        message: 'Message sent successfully! We\'ll get back to you soon.',
        severity: 'success',
      });

      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setSubmitStatus({
        show: true,
        message: 'Failed to send message. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <LayoutLandingPage>
      {/* Hero Section */}
      <Box
        sx={{
          color: 'white',
          pt: { xs: 15, md: 20 },
          pb: { xs: 8, md: 12 },
          backgroundImage: `url('/crowdml-header.svg')`, // Replace with your image name
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: '3rem', md: '4rem' },
              fontWeight: 700,
              textAlign: 'center',
              mb: 4,
              color:'black' 
            }}
          >
            Contact Us
          </Typography>
        </Container>
      </Box>

      {/* Contact Form Section */}
      <Box 
        sx={{ 
          py: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="md">
          <Card 
            elevation={3}
            sx={{
              maxWidth: 800,
              mx: 'auto',
              borderRadius: 2,
              bgcolor: 'background.paper',
            }}
          >
            <CardContent sx={{ p: { xs: 3, md: 6 } }}>
              <Stack spacing={4} alignItems="center">
                <Box textAlign="center" maxWidth="600px">
                  <Typography 
                    variant="h2" 
                    gutterBottom 
                    sx={{ 
                      fontSize: '2rem', 
                      fontWeight: 600,
                      mb: 2,
                    }}
                  >
                    Contact us
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary"
                    sx={{ mb: 4 }}
                  >
                    Have questions about our platform? Want to learn more about how we can help your ML team?
                    Get in touch with us and we'll be happy to help.
                  </Typography>
                </Box>

                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder="Hi there, I'm reaching out because I think we can collaborate..."
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={20} /> : <SendIcon />}
                        sx={{ 
                          px: 4,
                          py: 1.5,
                          borderRadius: 50,
                          minWidth: { xs: '100%', sm: 200 },
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Stack>
            </CardContent>
          </Card>
        </Container>
      </Box>

      {/* Team Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="lg">
          <Stack spacing={4} sx={{ mb: 6 }}>
            <Typography
              variant="overline"
              sx={{
                color: 'primary.main',
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              What we are best at
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 700,
                textAlign: 'center',
                mb: 4,
              }}
            >
              Our Team
            </Typography>
          </Stack>

          <Grid container spacing={4} justifyContent="center">
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 3,
                  }}
                >
                  <Avatar
                    src={member.avatar}
                    sx={{
                      width: 100,
                      height: 100,
                      mb: 2,
                      bgcolor: 'primary.main',
                    }}
                  />
                  <Typography variant="h6" gutterBottom>
                    {member.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {member.role}
                  </Typography>
                  <Divider sx={{ width: '100%', my: 2 }} />
                  <Stack spacing={1} alignItems="center">
                    <Link
                      href={`mailto:${member.email}`}
                      color="primary"
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <EmailIcon fontSize="small" />
                      {member.email}
                    </Link>
                    {/* <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <PhoneIcon fontSize="small" />
                      {member.phone}
                    </Typography> */}
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Snackbar
        open={submitStatus.show}
        autoHideDuration={6000}
        onClose={() => setSubmitStatus(prev => ({ ...prev, show: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSubmitStatus(prev => ({ ...prev, show: false }))}
          severity={submitStatus.severity} 
          variant="filled"
        >
          {submitStatus.message}
        </Alert>
      </Snackbar>
    </LayoutLandingPage>
  );
};

export default ContactUsPage;